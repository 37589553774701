import { Params } from "rext-image-editor/dist/models/models";

export const defaultParams : Params = {
  hdr: 0,
  exposure: 0,
  temperature: 0,
  tint: 0,
  brightness: 0,
  saturation: 0,
  contrast: 0,
  sharpen: 0,
  masking: 0,
  sharpen_radius: 0,
  radiance: 0,
  highlights: 0,
  shadows: 0,
  whites: 0,
  blacks: 0,
  dehaze: 0,
  bAndW: 0,
  atmosferic_light: 0,
  lightFill: 0,
  lightColor: 0,
  lightSat: 1,
  darkFill: 0,
  darkColor: 0,
  darkSat: 1,
  rotation: 0,
  rotation_center: {
    x: 0,
    y: 0,
  },
  scale: {
    x: 1,
    y: 1,
  },
  translate: {
    x: 0,
    y: 0,
  },
  size: {
    x: 1,
    y: 1,
  },
  zoom: 1,
};
